import { m } from 'framer-motion'
import { ReactNode } from 'react'

import Footer from '@components/footer'

type Props = {
  children?: ReactNode
}

export default function MainLayout({ children }: Props) {
  return (
    <m.main /* className="overflow-x-hidden" */>
      {children}
      <Footer />
    </m.main>
  )
}
